<template>
  <div class="flex justify-between items-center">
    <div class="flex justify-start items-center">
      <div class="w-56 pr-5 text-xs">
        Name
        <v-select v-model="selectedServerName" :items="serverNames" density="compact" item-title="name" rounded="0"
          variant="outlined" placeholder="Select Server Name" style="padding-top: 0px;padding-bottom: 0px;">
          <template v-slot:selection="{ item }">
            <v-list-item :title="item.raw.name" density style="padding: 0;">
              <template v-slot:prepend>
                <v-icon v-if="item.raw.name !== 'All'" :color="item.raw.conn_status ? 'green' : 'red'" size="10"
                  density="compact">mdi-circle</v-icon>
              </template>
            </v-list-item>
          </template>
          <template v-slot:item="{ props, item }">
            <v-list-item class="w-[250px]"  v-bind="props" :title="item.raw.name" density="compact">
              <template v-slot:prepend>
                <v-icon v-if="item.raw.name !== 'All'" :color="item.raw.conn_status ? 'green' : 'red'"
                  size="10">mdi-circle</v-icon>
              </template>
            </v-list-item>
          </template>
        </v-select>

      </div>
      <div v-if="selectedServerName !== ''" class="w-56 pr-5 text-xs">
        Server Type
        <v-select v-model="selectedServerType" :items="serverTypeList" density="compact" variant="outlined" rounded="0"
          placeholder="Select Sale Status" style="padding-top: 0px;padding-bottom: 0px;">
        </v-select>
      </div>
    </div>

  </div>
  <!-- <div>
    <v-dialog v-model="advanceSearchFlag" max-width="700">
      <AdvanceSearchDialog @close="advanceSearchFlag = false" @update-advance-filters="updateAdvanceFiltersHandler" />
    </v-dialog>
  </div> -->
</template>

<script setup>
import { ref, watch, defineEmits, watchEffect } from 'vue';

// import AdvanceSearchDialog from "./Modals/AdvanceSearchDialog.vue"
import { useAdminProxyStore } from "@/stores"
import { storeToRefs } from 'pinia';

const emit = defineEmits(['inInput', 'submit'])

const selectedServerName = ref("All")

const serverTypeList = ref(['ALL', 'X_PROXY', 'PROXYSMART'])
const selectedServerType = ref('ALL')

const adminProxyStore = useAdminProxyStore()
adminProxyStore.loadServerNames()
const { serverNames } = storeToRefs(adminProxyStore)
watch(selectedServerName, (newName) => {
  if (newName === 'All') {
    selectedServerType.value = 'X_PROXY'
  } else {
    const server = serverNames.value.find(item => item.name === newName)
    selectedServerType.value = server ? server.modem_type : 'X_PROXY'
  }
  emit('serverSelected', { name: selectedServerName.value, type: selectedServerType.value })
}, { immediate: true })

// Watch selectedServerType and emit serverSelected whenever it changes
watch(selectedServerType, (newType) => {
  emit('serverSelected', { name: selectedServerName.value, type: newType })
}, { immediate: true })
</script>