<template>
    <div class="modal fade" id="customerAddModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered  w-[500px] ">
            <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">

                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5">Create New User</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body ">
                        <div class="overflow-hidden">
                            <div class="float-left w-1/2 p-2 h-20">
                                User Name:
                                <v-text-field v-model="userName" variant="outlined" density="compact"
                                    rounded="0"></v-text-field>
                            </div>
                            <div class="float-left w-1/2 p-2 h-20">
                                Full Name:
                                <v-text-field v-model="fullName" variant="outlined" density="compact"
                                    rounded="0"></v-text-field>
                            </div>
                            <div class="float-left w-1/2 p-2 h-20">
                                Email:
                                <v-text-field v-model="email" variant="outlined" density="compact"
                                    rounded="0"></v-text-field>
                            </div>
                            <div class="float-left w-1/2 p-2 h-20">
                                Role:
                                <v-select v-model="role" :items="roleOptions" variant="outlined" density="compact"
                                    rounded="0"></v-select>
                            </div>
                            <div class="float-left w-1/2 p-2 h-20">
                                Balance:
                                <v-text-field v-model="balance" variant="outlined" density="compact" rounded="0"
                                    type="number"></v-text-field>
                            </div>
                            <div class="float-left w-1/2 p-2 h-20">
                                Address:
                                <v-text-field v-model="address" variant="outlined" density="compact"
                                    rounded="0"></v-text-field>
                            </div>
                            <div class="float-left w-1/2 p-2 h-20">
                                Country:
                                <country-select v-bind="field" name="country" :country="country" topCountry="US"
                                    :countryName="true" v-model="country"
                                    class="text-xs bg-gray outline-none w-full border text-black select-border px-[20px]"
                                    style="height: 44px;" />
                            </div>
                            <div class="float-left w-1/2 p-2 h-20">
                                City:
                                <region-select v-bind="field" name="city" :country="country" v-model="city"
                                    :region="city" :countryName="true" :regionName="true"
                                    class="text-xs bg-gray outline-none w-full border text-black select-border px-[20px]"
                                    style="height: 44px;" />
                            </div>
                            <div class="float-left w-1/2 p-2 h-20">
                                Password:
                                <Field name="password" v-slot="{ field }">
                                    <v-text-field v-bind="field" variant="outlined"
                                        :type="show_password ? 'text' : 'password'"
                                        :append-inner-icon="show_password ? 'visibility' : 'visibility_off'"
                                        density="compact" rounded="0" :error="errors.password ? true : false"
                                        :active="true" placeholder="Your Password" :error-messages="errors.password"
                                        @click:append-inner="show_password = !show_password"
                                        class="custom-text-field"></v-text-field>
                                </Field>
                            </div>
                            <div class="float-left w-1/2 p-2 h-20">
                                Confirm Password:
                                <Field name="confirm_password" v-slot="{ field }">
                                    <v-text-field v-bind="field" variant="outlined" type="password" density="compact"
                                        rounded="0" :error="errors.confirm_password ? true : false" :active="true"
                                        placeholder="Confirm Your Password" :error-messages="errors.confirm_password"
                                        class="custom-text-field"></v-text-field>
                                </Field>
                            </div>
                            <div class="float-left w-1/2 p-2 h-20">
                                Telegram:
                                <v-text-field v-model="tglink" variant="outlined" density="compact"
                                    rounded="0"></v-text-field>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        <button type="submit" :disabled="buttonDisableFlag" class="btn btn-primary">Create</button>
                    </div>
                </div>
            </Form>

        </div>
    </div>
</template>



<script setup>
import { ref, defineEmits } from "vue";
import { useAdminUserStore } from "@/stores";
import * as Yup from 'yup';
import { Form, Field } from 'vee-validate';

const userName = ref("");
const fullName = ref("");
const email = ref("");
const role = ref("");
const balance = ref("");
const country = ref("");
const city = ref("");
const address = ref("");
const tglink = ref("");

const buttonDisableFlag = ref(false)
const emit = defineEmits(['inInput', 'submit'])

const roleOptions = ref([
    'admin',
    'user',
]);
const adminUserStore = useAdminUserStore();

const onSubmit = (values) => {
    const createRequest = {
        userName: userName.value,
        fullName: fullName.value,
        email: email.value,
        role: role.value,
        balance: balance.value,
        country: country.value,
        city: city.value,
        address: address.value,
        password: values.password,
        tglink: tglink.value
    };
    buttonDisableFlag.value = true
    adminUserStore.createUser(createRequest).then(() => {
        buttonDisableFlag.value = false
        emit('updated')
    });
};

const schema = Yup.object().shape({
    password: Yup.string().required('Password is required').min(8),
    confirm_password: Yup.string().required('Confirm Password is required').oneOf([Yup.ref('password')], 'Passwords do not match'),
});

</script>
