<template>
  <div class="modal fade" id="xproxyUpdateModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered  w-[700px] ">
          <div class="modal-content">
              <div class="modal-header">
                  <h1 class="modal-title fs-5">Update XProxy</h1>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body ">
                  <div class="px-2 py-2 float-left w-full">
                      Upload quota
                      <div class=" flex items-center justify-between">
                          <v-radio-group v-model="uploadLimitType" inline class="pr-5">
                              <v-radio label="Unlimited" value="unlimited"></v-radio>
                              <v-radio label="Limited (MB)" value="limited"></v-radio>
                          </v-radio-group>
                          <div class="flex w-1/2" v-if="uploadLimitType === 'limited'">
                              <v-text-field v-model="uploadLimitAmount" variant="outlined" density="compact"
                                  rounded="0" type="number" style="" class=" mr-5"></v-text-field>
                              <v-select v-model="uploadLimitTimeUnit" :items="timeUnitTypes" variant="outlined"
                                  rounded="0" density="compact" class="w-1/2"></v-select>
                          </div>
                      </div>
                  </div>
                  <!-- Download quota -->
                  <div class="px-2 py-2 float-left w-full">
                      Download quota
                      <div class="flex items-center justify-between">
                          <v-radio-group v-model="downloadLimitType" inline class="pr-5">
                              <v-radio label="Unlimited" value="unlimited"></v-radio>
                              <v-radio label="Limited (MB)" value="limited"></v-radio>
                          </v-radio-group>
                          <div class="flex w-1/2" v-if="downloadLimitType === 'limited'">
                              <v-text-field v-model="downloadLimitAmount" variant="outlined" density="compact"
                                  rounded="0" inputmode="numeric" type="number" style="" class="mr-5"></v-text-field>
                              <v-select v-model="downloadLimitTimeUnit" :items="timeUnitTypes" variant="outlined"
                                  rounded="0" density="compact" class="w-1/2"></v-select>
                          </div>
                      </div>
                  </div>

                  <!-- Up/Down quota -->
                  <div class="px-2 py-2 float-left w-full">
                      Up/Down quota
                      <div class="flex items-center justify-between">
                          <v-radio-group v-model="upDownLimitType" inline class="pr-5">
                              <v-radio label="Unlimited" value="unlimited"></v-radio>
                              <v-radio label="Limited (MB)" value="limited"></v-radio>
                          </v-radio-group>
                          <div class="flex w-1/2" v-if="upDownLimitType === 'limited'">
                              <v-text-field v-model="upDownLimitAmount" variant="outlined" density="compact"
                                  rounded="0" type="number" style="" class="mr-5"></v-text-field>
                              <v-select v-model="upDownLimitTimeUnit" :items="timeUnitTypes" variant="outlined"
                                  rounded="0" density="compact" class="w-1/2"></v-select>
                          </div>
                      </div>
                  </div>

                  <!-- Bandwidth limit -->
                  <div class="px-2 py-2 float-left w-full">
                      Bandwidth limit
                      <div class="flex items-center justify-between">
                          <v-radio-group v-model="bandLimitType" inline>
                              <v-radio label="Unlimited" value="unlimited"></v-radio>
                              <v-radio label="Limited (MB)" value="limited"></v-radio>
                          </v-radio-group>
                          <div class="flex w-1/2" v-if="bandLimitType === 'limited'">
                              <v-text-field v-model="bandLimitAmount" variant="outlined" density="compact" rounded="0"
                                  type="number" style=""></v-text-field>
                          </div>
                      </div>
                  </div>
                  <div class="px-2 py-2 float-left w-full">
                      Custom DNS:
                      <div class="flex items-center justify-between mt-[8px]">
                          <div class="flex w-full">
                              <v-text-field v-model="customDNS" variant="outlined" density="compact" rounded="0"
                                   style=""></v-text-field>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                  <button type="button" :disabled="buttonDisableFlag" class="btn btn-primary"
                      @click="updateProxy">Update</button>
              </div>
          </div>
      </div>
  </div>
</template>


<script setup>
import { ref, watchEffect, defineEmits, defineProps } from 'vue';
import { useAdminProxyStore, useAlertStore } from "@/stores"

const emit = defineEmits(['inInput', 'submit'])

const adminProxyStore = useAdminProxyStore()
const props = defineProps({
  item: {
      type: Object, // Assuming user object has a name property
      required: true
  },
});

const buttonDisableFlag = ref(false)

const uploadLimitAmount = ref("");
const uploadLimitTimeUnit = ref('');
const downloadLimitAmount = ref("");
const downloadLimitTimeUnit = ref('');
const upDownLimitAmount = ref("");
const upDownLimitTimeUnit = ref('');
const bandLimitAmount = ref("");
const customDNS = ref('')
const uploadLimitType = ref("");
const downloadLimitType = ref("");
const upDownLimitType = ref("");
const bandLimitType = ref("");
const timeUnitTypes = ref(['DAILY', 'WEEKLY', 'MONTHLY'])
// const uploadLimitAmount = ref(0);
// const uploadLimitTimeUnit = ref('DAY');
// const downloadLimitAmount = ref(0);
// const downloadLimitTimeUnit = ref('DAY');
// const upDownLimitAmount = ref(0);
// const upDownLimitTimeUnit = ref('DAY');
// const bandLimitAmount = ref(0);
// const customDNS = ref('')
// const uploadLimitType = ref('unlimited');
// const downloadLimitType = ref('unlimited');
// const upDownLimitType = ref('unlimited');
// const bandLimitType = ref('unlimited');
// const timeUnitTypes = ref(['DAY', 'WEEK', 'MONTH'])
watchEffect(() => {
  uploadLimitAmount.value = props?.item ? props.item.uploadLimitAmount : "0";
  uploadLimitTimeUnit.value = props?.item ? props.item.uploadLimitTimeUnit : "DAILY";
  downloadLimitAmount.value = props?.item ? props.item.downloadLimitAmount : "0";
  downloadLimitTimeUnit.value = props?.item ? props.item.downloadLimitTimeUnit : "DAILY";
  upDownLimitAmount.value = props?.item ? props.item.upDownLimitAmount : "0";
  upDownLimitTimeUnit.value = props?.item ? props.item.upDownLimitTimeUnit : "DAILY";
  bandLimitAmount.value = props?.item ? props.item.bandLimitAmount : "0";
  customDNS.value = props?.item ? props.item.customDNS : "";
  uploadLimitType.value = props?.item ? props.item.uploadLimitType : "unlimited";
  downloadLimitType.value = props?.item ? props.item.downloadLimitType : "unlimited";
  upDownLimitType.value = props?.item ? props.item.upDownLimitType : "unlimited";
  bandLimitType.value = props?.item ? (props.item.bandLimitType ? 'limited' : 'unlimited') : "unlimited";
})
function updateProxy() {
  const proxyRequest = {
      uploadLimitAmount: parseInt(uploadLimitAmount.value),
      uploadLimitTimeUnit: uploadLimitTimeUnit.value,
      downloadLimitAmount: parseInt(downloadLimitAmount.value),
      downloadLimitTimeUnit: downloadLimitTimeUnit.value,
      upDownLimitAmount: parseInt(upDownLimitAmount.value),
      upDownLimitTimeUnit: upDownLimitTimeUnit.value,
      bandLimitAmount: parseInt(bandLimitAmount.value),
      customDNS: customDNS.value,
      uploadLimitType: uploadLimitType.value === "unlimited" ? 0 : 1,
      downloadLimitType: downloadLimitType.value === "unlimited" ? 0 : 1,
      upDownLimitType: upDownLimitType.value === "unlimited" ? 0 : 1,
      bandLimitType: bandLimitType.value === "unlimited" ? 0 : 1,
  };
  buttonDisableFlag.value = true
  adminProxyStore.updateProxy(props.item.uuid, props.item.modem_type, props.item.modem_id, proxyRequest).then(() => {
      buttonDisableFlag.value=false
      emit('close');
  })
}

// watchEffect(() => {
//   uploadLimitAmount.value = Math.max(0, uploadLimitAmount.value)
//   downloadLimitAmount.value = Math.max(0, downloadLimitAmount.value)
//   upDownLimitAmount.value = Math.max(0, upDownLimitAmount.value)
//   bandLimitAmount.value = Math.max(0, bandLimitAmount.value)
// })

</script>