<template>
    <div class="modal fade" id="packageAddModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered  w-[500px] ">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5">Add New Package</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body ">
                    <div class="overflow-hidden">
                        <div class="float-left w-1/2 px-2 py-2 h-20">
                            Package Name:
                            <v-text-field v-model="packageName" variant="outlined" density="compact"
                                rounded="0"></v-text-field>
                        </div>
                        <div class="float-left w-1/2 px-2 py-2 h-20">
                            Price:
                            <v-text-field v-model="price" variant="outlined" density="compact" rounded="0" type="number"
                                style=""></v-text-field>
                        </div>
                        <!-- <div class="float-left w-1/2 px-2 py-2 h-20">
                    Time (Date):
                    <v-text-field v-model="timeDate" variant="outlined" density="compact" rounded="0" type="number"></v-text-field>
                </div> -->
                        <!-- <div class="w-full flex"> -->

                        <div class="w-1/2 float-left px-2 py-2 h-20 ">
                            Time Unit:
                            <div>
                                <v-btn-toggle v-model="timeUnit" color="deep-purple-accent-3" group mandatory
                                    rounded="0" style="height: 40px;" class="border w-full">
                                    <v-btn value="DAY" class="w-1/3 bg-white text-black h-full"
                                        style="outline: none; height: 100%;">
                                        <p class="text-xs">Day</p>
                                    </v-btn>
                                    <v-btn value="WEEK" class="w-1/3 bg-white text-black h-full"
                                        style="outline: none; height: 100%;">
                                        <p class="text-xs">Week</p>
                                    </v-btn>
                                    <v-btn value="MONTH" class="w-1/3 bg-white text-black h-full"
                                        style="outline: none; height: 100%;">
                                        <p class="text-xs">Month</p>
                                    </v-btn>
                                </v-btn-toggle>
                            </div>

                        </div>
                        <div class="w-1/2 float-left px-2 py-2 h-20">
                            Status:
                            <v-select v-model="status" :items="statusOptions" variant="outlined" density="compact"
                                rounded="0"></v-select>
                        </div>
                        <div class="w-1/2 float-left px-2 py-2 h-20">
                            Minimum IP Rotation Time:
                            <v-text-field v-model="rotationMin" variant="outlined" density="compact" rounded="0"
                                type="number" style=""></v-text-field>
                        </div>
                        <div class="w-1/2 float-left px-2 py-2 h-20">
                            Daily Data Limits (GB):
                            <v-text-field v-model="dataLimits" variant="outlined" density="compact" rounded="0" step="0.1"
                                type="" style=""></v-text-field>
                        </div>
                        <div class="w-1/2 float-left px-2 py-2 h-20">
                            Supplier:
                            <v-combobox v-model="supplier" :items="customers" placeholder="Select" rounded="0"
                                variant="outlined" style="outline: none;" density="compact" :hide-no-data="false">
                                <template v-slot:no-data>
                                    <v-list-item density>
                                        <v-list-item-title density="compact" variant="disabled">
                                            <p class="text-sm text-gray-600 text-center">Not found</p>
                                        </v-list-item-title>
                                    </v-list-item>
                                </template>
                            </v-combobox>
                        </div>
                        <div class="w-1/2 float-left px-2 py-2 h-20">
                            Cost price:
                            <v-text-field v-model="costPrice" variant="outlined" density="compact" rounded="0"
                                type="number" style=""></v-text-field>
                        </div>

                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" :disabled="buttonDisableFlag" class="btn btn-primary"
                        @click="handleCreate">Create</button>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
import { ref, watchEffect, defineProps, defineEmits } from 'vue';
import { useAdminPackageStore, useAlertStore } from "@/stores"
import { storeToRefs } from 'pinia';

const adminPackageStore = useAdminPackageStore()
const alertStore = useAlertStore()
const props = defineProps({
    serverName: {
        type: Object, // Assuming user object has a name property
        required: true
    },
});
const { customers } = storeToRefs(adminPackageStore)
adminPackageStore.getCustomers()

const buttonDisableFlag = ref(false)
const PackagePrewords = { "DAY": "24 Hours", "WEEK": "7 Days", "MONTH": "1 Month" }
const selectedServerName = ref(props.serverName);
const price = ref(0);
const timeDate = ref(1);
const timeUnit = ref('DAY');
const status = ref('Active');
const ipChangeTime = ref(0);
const dataLimits = ref(2);
const rotationMin = ref(5);
const supplier = ref('')
const costPrice = ref(null)
// const changeIpFlag = ref(true);
// const changeLocationFlag = ref(true);
const imageUrl = ref(null);
const fileInput = ref(null)
const onFileChange = (event) => {
    const file = event.target.files[0];
    imageUrl.value = URL.createObjectURL(file);
};
const openFileInput = () => {
    fileInput.value.$el.querySelector('input').click();
};

watchEffect(() => {
    if (props.serverName){
        selectedServerName.value = props.serverName
        supplier.value = ''
        costPrice.value = null
    }
}, [props.serverName])
const packageName = ref(PackagePrewords[timeUnit.value] + " - " + selectedServerName.value);
const emit = defineEmits(['inInput', 'submit'])

const statusOptions = ref([
    'Active',
    'Off',
]);
const handleCreate = () => {
    if(supplier.value) {
        if(!costPrice.value) {
            alertStore.error("Set the cost price if this is the supplier's.")
            return;
        } else {
            if(costPrice.value > price.value) {
                alertStore.error("Cost price is bigger than package price")
                return;
            }
        }
    }
    let formData = new FormData();
    const packageRequest = {
        packageName: packageName.value,
        price: price.value,
        timeDate: timeDate.value,
        timeUnit: timeUnit.value,
        status: status.value,
        ipChangeTime: rotationMin.value ? rotationMin.value : 0,
        dataLimits: dataLimits.value,
        rotationMin: rotationMin.value,
        supplier: supplier.value ? supplier.value : '',
        costPrice: costPrice.value ? costPrice.value : 0
        // changeIpFlag: changeIpFlag.value,
        // changeLocationFlag: changeLocationFlag.value,
    };
    // Append package data to FormData
    for (const key in packageRequest) {
        formData.append(key, packageRequest[key]);
    }

    // Append image file to FormData
    formData.append('serverName', selectedServerName.value);

    // const file = fileInput.value.$el.querySelector('input').files[0];
    // formData.append('file', file);

    buttonDisableFlag.value = true;
    // Send FormData to server
    adminPackageStore.createNewPackage(formData).then(() => {
        adminPackageStore.loadPackages(selectedServerName.value);
        buttonDisableFlag.value = false;
        emit('close');
    });
};


watchEffect(() => {
    packageName.value = PackagePrewords[timeUnit.value] + " - " + selectedServerName.value
}, [packageName.value])

watchEffect(() => {
    price.value = Math.max(price.value, 0)
    dataLimits.value = Math.max(dataLimits.value, 0)
    rotationMin.value = Math.max(rotationMin.value, 0)
}, [price.value, rotationMin.value, dataLimits.value])

</script>
