<template>
    <div class="modal fade" id="licenseUpdateModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered w-[700px]">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5">Update License</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body" v-if="selectedItem">
                    <div class="">
                        <div class="float-left w-1/2 px-2 py-2 h-20">
                            Customer:
                            <v-text-field v-model="customerName" variant="outlined" density="compact" rounded="0"
                                readonly></v-text-field>
                        </div>
                        <div class="float-left w-1/2 px-2 py-2 h-20">
                            Status:
                            <v-select v-model="status" :items="statusOptions" variant="outlined" rounded="0"
                                density="compact"></v-select>
                        </div>
                        <div class="float-left w-1/2 px-2 py-2 h-20">
                            Start Date:
                            <VueDatePicker  v-model="startDate" format='dd/MM/yyyy HH:mm' class="text-black bg-white"></VueDatePicker >
                            <!-- <v-text-field v-model="startDate" variant="outlined" density="compact" rounded="0"
                                type="datetime-local"></v-text-field> -->
                        </div>
                        <div class="float-left w-1/2 px-2 py-2 h-20">
                            Expiration Date:
                            <VueDatePicker  v-model="expiredDate" format='dd/MM/yyyy HH:mm' class="text-black bg-white"></VueDatePicker >
                            <!-- <v-text-field v-model="expiredDate" variant="outlined" density="compact" rounded="0"
                                type="datetime-local"></v-text-field> -->
                        </div>
                        <div class="float-left w-1/2 px-2 py-2 h-20">
                            Package Name:
                            <v-select v-model="packageName" :items="PackageNameList.map(item => item.name)"
                                variant="outlined" rounded="0" density="compact"></v-select>
                        </div>
                        <div class="float-left w-1/2 px-2 py-2 h-20">
                            Auto Rotation Time:
                            <div class="flex space-x-4">
                                <v-text-field v-model="rotationTime" variant="outlined" density="compact" type="number"
                                    rounded="0" :disabled="!rotationEnabled" style="outline: none;"></v-text-field>
                                <v-switch v-model="rotationEnabled" color="success" density="compact"></v-switch>
                            </div>
                        </div>
                        <div class="float-left w-1/2 px-2 py-2 h-20">
                            <span @click="authType = 'username'" style="cursor: pointer;" class="flex">
                                <div class="w-5 mr-4">
                                    <input :checked="authType === 'username'" class="form-check-input shadow-none"
                                        type="checkbox" />
                                </div>
                                <span class="text-sm">Authentication User:</span>
                            </span>
                            <v-text-field v-model="auth_user" variant="outlined" density="compact" rounded="0"
                                :disabled="authType !== 'username'">
                                <template v-slot:append-inner>
                                    <v-icon icon="mdi-refresh" @click="generateRandomCredentials"></v-icon>
                                </template>
                            </v-text-field>
                        </div>
                        <div class="float-left w-1/2 px-2 py-2 h-20 object-center">
                            <span @click="authType = 'ip'" style="cursor: pointer;" class="flex">
                                <div class="w-5 mr-4">
                                    <input :checked="authType === 'ip'" class="form-check-input shadow-none"
                                        type="checkbox" />
                                </div>
                                <span class="text-sm">Authentication IPs:</span>
                            </span>
                            <v-text-field v-model="auth_ips" variant="outlined" density="compact" rounded="0"
                                :disabled="authType !== 'ip'"></v-text-field>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" :disabled="buttonDisableFlag" class="btn btn-primary"
                        @click="handleUpdate">Update</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, watchEffect } from "vue";
import { useAdminPackageStore, useAdminLicenseStore, useAlertStore } from "@/stores";
import { storeToRefs } from 'pinia';
import VueDatePicker from '@vuepic/vue-datepicker';

const adminPackageStore = useAdminPackageStore();
const adminLicenseStore = useAdminLicenseStore();
const buttonDisableFlag = ref(false);

const props = defineProps({
    item: {
        type: Object,
        required: true
    },
});

const statusOptions = ref([
    'ACTIVE',
    'EXPIRED'
]);

const selectedItem = ref(null);
const customerName = ref(null);
const isp = ref(null);
const startDate = ref();
const expiredDate = ref();
const packageName = ref(null);
const status = ref(null);
const rotationTime = ref(null);
const auth_user = ref(null);
const auth_ips = ref(null);
const rotationEnabled = ref(false);
const authType = ref('username');

const emit = defineEmits(['updated']);

const { PackageNameList } = storeToRefs(adminPackageStore);

watchEffect(() => {
    if (props.item) {
        selectedItem.value = props.item;
        adminPackageStore.getPackageNameList(props.item?.modem_name);
    }
}, [props.item]);

watchEffect(() => { 
    if (selectedItem.value) {
        customerName.value = selectedItem.value.customer_name || null;
        isp.value = selectedItem.value.isp || null;
        startDate.value = selectedItem.value.start_date ? (new Date(selectedItem.value.start_date)) : (new Date());
        expiredDate.value = selectedItem.value.expired_date ? (new Date(selectedItem.value.expired_date)) : (new Date());
        packageName.value = selectedItem.value.package_name || null;
        status.value = selectedItem.value.status || null;
        rotationTime.value = Math.max(selectedItem.value.auto_rotation_time, selectedItem.value.min_time_change_ip) || null;
        auth_user.value = selectedItem.value.auth_user || null;
        auth_ips.value = selectedItem.value.auth_ips || null;
        rotationEnabled.value = selectedItem.value.auto_rotation_time !== -1;
        authType.value = (selectedItem.value.auth_user !== null && selectedItem.value.auth_user !== "") ? 'username' : 'ip';
        
    } else {
        // Optional: Reset values if selectedItem is null
        customerName.value = null;
        isp.value = null;
        startDate.value = new Date();
        expiredDate.value = new Date();
        packageName.value = null;
        status.value = null;
        rotationTime.value = null;
        auth_user.value = null;
        auth_ips.value = null;
        rotationEnabled.value = false;
        authType.value = 'ip';
    }
});

watchEffect(() => {
    if (packageName.value) {
        const time_unit = PackageNameList.value.find(item => item.name === packageName.value)?.time_unit;
        let newDate = new Date(selectedItem.value.start_date);

        if (time_unit === 'DAY') {
            newDate.setDate(newDate.getDate() + 1);
        } else if (time_unit === 'WEEK') {
            newDate.setDate(newDate.getDate() + 7);
        } else if (time_unit === 'MONTH') {
            const monthsToAdd = parseInt(packageName.value, 10);
            newDate.setMonth(newDate.getMonth() + monthsToAdd);
        }
        // expiredDate.value = (newDate)
    }
}, [packageName.value]);

watchEffect(() => {
    rotationTime.value = Math.max(rotationTime.value, selectedItem.value?.min_time_change_ip)
}, [rotationTime.value]);

const handleUpdate = () => {
    let temp_auth_user = auth_user.value;
    let temp_auth_ips = auth_ips.value;
    const alertStore = useAlertStore()

    if (authType.value === "username") {
        temp_auth_ips = null;
    } else if (authType.value === "ip") {
        temp_auth_user = null;
    }
    if(authType.value === "username"){
        if(temp_auth_user === "" || temp_auth_user === null) {
            alertStore.error("Please enter username and password");
            return
        }
        const userpass = temp_auth_user?.split(':');
        if(userpass.length < 2) {
            alertStore.error("Please enter username and password correctly");
            return
        }
        if(userpass[0].length < 4 || userpass[1].length < 4) {
            alertStore.error("Please lengthen username and password to 4 characters or more");
            return
        }
    }
    if(authType.value === "ip") {
        if(temp_auth_ips === null || temp_auth_ips === "") {
            alertStore.error("Please enter auth ips");
            return
        }
    }
    const updateRequest = {
        uuid: selectedItem.value.uuid,
        customer_name: customerName.value,
        isp: isp.value,
        start_date:    new Date(startDate.value).toISOString(),
        expired_date:  new Date(expiredDate.value).toISOString(),
        package_name: packageName.value,
        status: status.value,
        auth_user: temp_auth_user,
        auth_ips: temp_auth_ips,
        auto_rotation_time: rotationEnabled.value ? rotationTime.value : -1
    };

    buttonDisableFlag.value = true;

    adminLicenseStore.updateLicense(updateRequest).then(() => {
        buttonDisableFlag.value = false;
        emit('updated');
    });
};

function generateRandomCredentials() {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let user_name = '';
    let password = '';
    for (let i = 0; i < 7; i++) {
        user_name += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    for (let i = 0; i < 7; i++) {
        password += Math.floor(Math.random() * 10); // Generates a random digit between 0 and 9
    }
    const newUsername = `u${user_name}:p${password}`
    auth_user.value = newUsername;
}

</script>

<style scoped>
.clear-both {
    clear: both;
}
</style>