<template>
  <header style="height: 74px;"
   :class="[
    'header-area bg-white text-center text-md-start pl-3 pr-10 transition mb-25 position-fixed ',
    { sticky: isSticky },
  ]" id="header">
    <div class="row flex items-center h-full">
      <div class="col-xl-2 col-lg-3 col-md-4">
        <div class="header-left-side justify-content-center justify-content-md-start d-flex align-items-center">
          <button class="header-burger-menu transition position-relative lh-1 bg-transparent p-0 border-0 text-black"
            id="header-burger-menu" @click="stateStoreInstance.onChange">
            <i class="flaticon-menu-3"></i>
          </button>
        </div>
      </div>
      <div class="col-xl-10 col-lg-9 col-md-8">
        <div class="header-right-side d-flex align-items-center justify-content-center justify-content-md-end">
          <AccountInfo  v-if="user&&user.role==='user'" />
          <AdminAccountInfo  v-else-if="user&&user.role==='admin'" />
          <LightDarkSwtichBtn />

          <div class="dropdown profile-dropdown">
            <button
              class="dropdown-toggle text-start fs-14 text-black-emphasis d-flex align-items-center p-0 position-relative bg-transparent border-0 transition lh-1"
              type="button" data-bs-toggle="dropdown" aria-expanded="false">
              <img src="../../assets/images/default-user-avatar.png" class="rounded" width="44" height="44"
                alt="admin" />
              <span class="title d-none d-lg-block ms-10 ms-lg-15">
                <span class="d-block fw-bold mb-5 mb-md-8">{{ user.name }}</span>
                <span class="text-body-emphasis fw-semibold fs-13">{{ user.role }}</span>
              </span>
            </button>
            <div class="dropdown-menu rounded-0 bg-white border-0 start-auto end-0">
              <ul class="ps-0 mb-0 list-unstyled dropdown-body">
                <li class="text-body-secondary fw-semibold transition position-relative">
                  <i class="flaticon-user-2"></i>
                  My Account
                  <router-link to="/account"
                    class="d-block position-absolute start-0 top-0 end-0 bottom-0 text-decoration-none"></router-link>
                </li>
                <li class="text-body-secondary fw-semibold transition position-relative">
                  <i class="flaticon-setting"></i>
                  Settings
                  <router-link to="/account-setting"
                    class="d-block position-absolute start-0 top-0 end-0 bottom-0 text-decoration-none"></router-link>
                </li>
                <li class="text-body-secondary fw-semibold transition position-relative cursor-pointer"
                  @click="signOut">
                  <i class="flaticon-logout"></i>
                  Logout
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import LightDarkSwtichBtn from "./LightDarkSwtichBtn.vue";
import stateStore from "../../utils/store";
import AccountInfo from "@/components/Customer/AccountInfoForHeader.vue";
import AdminAccountInfo from "@/components/Admin/AccountInfoForHeader.vue";
import { useAccountStore } from "@/stores"
import { storeToRefs } from "pinia"
import { Tooltip } from "bootstrap";

export default defineComponent({
  name: "MainHeader",
  components: {
    AccountInfo,
    AdminAccountInfo,
    LightDarkSwtichBtn,
  },
  setup() {
    const stateStoreInstance = stateStore;
    const isSticky = ref(false);

    const accountStore = useAccountStore()
    const { user } = storeToRefs(accountStore)
    onMounted(() => {
      window.addEventListener("scroll", () => {
        let scrollPos = window.scrollY;
        isSticky.value = scrollPos >= 100;
      });
      const tooltipTriggerList = [].slice.call(
        document.querySelectorAll('[data-bs-toggle="tooltip"]')
      );
      tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl);
      });
    });
    const signOut = () => {
      accountStore.signout()
    }
    return {
      isSticky,
      stateStoreInstance,
      user,
      signOut
    };
  },
});
</script>