<template>
  <div class="chatOpen bg-white text-dark " v-if="chatOpen">
    <div class="flex flex-row justify-between m-2">
      <p @click="changeChatState" >Live Support</p>
      <button @click="changeChatState" ><img src="../../assets/close.png" /></button>
    </div>
    <div ref="chatContent" class="chatContent">
      <div v-for="message in messageList" class="message" :key="message.id">
        <div v-if="message.from === user.id" class=" container flex-col flex w-full text py-2 px-4 darker">
          <p>{{ message.text }}</p>
          <span class="time-left">{{ formatDateTime(message.timestamp) }}</span>
          </div>
        <div v-else class=" container flex w-full justify-start flex-col text py-2 px-4">
          <p>{{ message.text }}</p>
          <span class="time-right">{{ formatDateTime(message.timestamp) }}</span>
        </div>
      </div>
    </div>
    <div class="w-full flex flex-row items-center">
      <v-text-field ref="input" v-model="messageInput" variant="outlined" density="compact" placeholder="Type your message here" @keyup.enter="send" 
        rounded="1" style=""></v-text-field>
      <v-icon icon="mdi-send" color="blue" size="35px" @click="send"></v-icon>
      <!-- <img src="../../assets/send.png" class="w-[20px] mx-2"/> -->
    </div>
  </div>
  <div v-else class="chatClose">
    <button @click="changeChatState" class="chatClose__buttonForOpenChat" style="width: 80px; height: 80px">
      <p class="text-[15px]">Live Support</p>
      <span v-if="unread_count !== 0" class=" position-absolute z-10 start-[90%] text-[14px] min-w-[30px] top-4 translate-middle badge rounded-pill flex bg-danger items-center justify-center aspect-square ">{{unread_count}}</span>
    </button>
  </div>
</template>

<script setup>
import {useChat} from "@/firebase.js";
import { useAccountStore, useAlertStore } from "@/stores"
import { storeToRefs } from "pinia"
const accountStore = useAccountStore()
const alertStore = useAlertStore();
const {user} = storeToRefs(accountStore)
import {computed, nextTick, onMounted, ref, watch} from 'vue'
const {messages, sendMessage, setUserId, unread_count, markMessagesAsRead, loadMoreMessages} = useChat()
const chatOpen = ref(false)
const messageInput = ref('')
const chatContent = ref(null);
const input = ref(null)
// const messages = ref([])

const messageList = computed(() => {
  return [...messages.value].reverse();
});
const changeChatState = () => {
  chatOpen.value = !chatOpen.value;
  if(chatOpen.value) 
    nextTick( async () => {
      markMessagesAsRead()
      chatContent.value.scrollTop = chatContent.value.scrollHeight;
    });
    
}

let loadingMoreMessages = false;

const handleScroll = () => {
  if (chatContent.value.scrollTop === 0 && !loadingMoreMessages) {
    const previous = chatContent.value.scrollHeight
    loadingMoreMessages = true;
    loadMoreMessages().then(() => {
      nextTick(() => {
        loadingMoreMessages = false;
        chatContent.value.scrollTop = chatContent.value.scrollHeight - previous;
      });
    });
  }
};

const send = () => {
  if(messageInput.value === '' || !user.value?.adminid) {
    alertStore.error("Please enter message")
    return;
  }
  sendMessage(messageInput.value, user.value.id, user.value.adminid)
  loadingMoreMessages = false
  messageInput.value = ''
  nextTick(() => {
    input.value.focus()
  })
}

onMounted(() => {
  setUserId(user.value.id, false)
  if(chatOpen.value){
    chatContent.value.scrollTop = chatContent.value.scrollHeight;
    input.value.focus()
  }
})
const formatDateTime = (timestamp) => {
    const date = timestamp.toDate();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${year}.${month}.${day}  ${hours}:${minutes}`;
};
watch(messages, () => {
  if(chatOpen.value){
    // const previous = chatContent.value.scrollHeight;
    nextTick( async () => {
      if (!loadingMoreMessages) {
        chatContent.value.scrollIntoView({behavior: 'smooth'})
        markMessagesAsRead()
        if (chatContent.value) {
          chatContent.value.scrollTop = chatContent.value.scrollHeight;
        }
      }
    })
  }
}, {deep: true})

</script>

<style lang="scss" scoped>
.chatOpen {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 600px;
  border-radius: 10px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  background-color: white;
  padding: 5px;
  border: rgba(0, 0, 0, 0.5) solid 2px;
  z-index: 1000;
}

.chatClose {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 200;

  &__buttonForOpenChat {
    width: 55px;
    height: 55px;
    background-color: #42b983;
    border-radius: 50%;
    color: aliceblue;
    font-weight: bold;
    font-size: 30px;
    border: black solid 2px;
    transition-duration: .3s;
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
      transition-duration: .3s;
    }
  }
}

.chatContent {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 5px;
  width: 100%;
  height: 100%;
  border: 1px solid black;
  background-color: rgba(66, 185, 131, 0.14);
  border-radius: 10px 10px 0 0;
  overflow-y: auto;
}


.container {
  border: 2px solid #dedede;
  background-color: #576f30;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 10px;
  margin: 10px 0;
  width: 60%;
  margin: 10px;
}
.container::after {
  content: "";
  clear: both;
  display: table;
}
.darker {
  border-color: #ccc;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 30px;
  background-color: #6560f0;
  float: right;
}

.time-right {
  display: flex;
  justify-content: end;
  color: #ebeaea;
}

/* Style time text */
.time-left {
  float: left;
  color: #fdd2d2;
}
</style>
