<template>
    <div class="modal fade" id="modemChangeModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered  w-[700px] ">
            <div class="modal-content" v-if="props.license_uuid && props.selectedServerName">
                <div class="modal-header">
                    <h1 class="modal-title fs-5">Change Modem</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body ">
                    <div class="overflow-hidden">
                        <div class="float-left w-1/2 px-5 h-20">
                            Modem Name:
                            <v-select v-model="selectedModemName" :items="modemList.map(modem => modem.name)"
                                variant="outlined" density="compact"></v-select>
                        </div>
                        <div class="float-left w-1/2 px-5 h-20">
                            ISP:
                            <v-text-field v-model="selectedModemISP" variant="outlined" density="compact"
                                readonly></v-text-field>
                        </div>
                        <div class="float-left w-1/2 px-5 h-20">
                            Proxies:
                            <v-select v-model="selectedProxy"
                                :items="proxyList.map(proxy => `${proxy.shared_port}/${proxy.brother_port}`)"
                                variant="outlined" density="compact"></v-select>
                        </div>
                        <div class="float-left w-1/2 px-5 h-20">
                            Packages:
                            <v-select v-model="selectedPackage" :items="packageList.map(pkg => pkg.name)"
                                variant="outlined" density="compact"></v-select>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" :disabled="buttonDisableFlag" class="btn btn-primary"
                        @click="handleUpdate">Change</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watchEffect, defineProps, defineEmits } from "vue";

import { useAdminLicenseStore } from "@/stores"
const emit = defineEmits(['inInput', 'submit'])

const adminLicenseStore = useAdminLicenseStore()
const buttonDisableFlag = ref(false)
const props = defineProps({
    selectedServerName: {
        type: String, // Assuming user object has a name property
        required: true
    },
    license_uuid: {
        type: String,
        required: true
    }
});

const selectedModemName = ref("")
const selectedModemISP = ref("")
const selectedProxy = ref("")
const selectedPackage = ref("")

const modemList = ref([])
const proxyList = ref([])
const packageList = ref([])


watchEffect(async () => {
    if (props.license_uuid) {
        modemList.value = await adminLicenseStore.getModemList(props.license_uuid)
        if (modemList.value && modemList.value.length > 0)
            selectedModemName.value = modemList.value[0].name
    }
}, [props.license_uuid])

watchEffect(async () => {
    if (selectedModemName.value !== "") {
        const selectedModem = modemList.value.find(item => item.name === selectedModemName.value)
        if (selectedModem)
            selectedModemISP.value = selectedModem.isp

        const { proList, pkgList } = await adminLicenseStore.getProxiesAndPackages(selectedModem.name)
        proxyList.value = proList
        packageList.value = pkgList
        if (proList.length > 0) {
            selectedProxy.value = `${proxyList.value[0].shared_port}/${proxyList.value[0].brother_port}`
        }
        else {
            selectedProxy.value = null
        }
        packageList.value = pkgList
        if (pkgList.length > 0) {
            selectedPackage.value = packageList.value[0].name
        }
        else {
            selectedPackage.value = null
        }
    }
}, [selectedModemName.value])

const handleUpdate = () => {
    const updateRequest = {
        license_uuid: props.license_uuid,
        modemName: selectedModemName.value,
        proxyName: selectedProxy.value,
        packageName: selectedPackage.value,
    }
    buttonDisableFlag.value = true
    adminLicenseStore.changeModem(updateRequest).then(() => {
        buttonDisableFlag.value = false
        emit('updated')
    })
}
</script>

<style scoped>
.clear-both {
    clear: both;
}
</style>