<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing lead-info-box" v-if="user">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
      <div class="d-flex align-items-center justify-content-between">
        <h5 class="fw-medium text-black-emphasis mb-0">Site Information</h5>
        <v-btn icon="mdi-pencil" size="small" style="outline: none;"
                    class="bg-dark text-light" data-bs-toggle="modal" data-bs-target="#metaEdit"
                    @click="addLicense"></v-btn>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
      <div class="d-flex flex-col">
        <h5 class="fw-medium mb-0 text-[17px] pb-10">Site Title:</h5>
        <h5 class="fw-medium mb-0 pl-5 text-[15px] pb-20">{{siteTitle}}</h5>
      </div>
      <div class="d-flex flex-col">
        <h5 class="fw-medium mb-0 text-[17px] pb-10">Site Description:</h5>
        <h5 class="fw-medium mb-0 pl-5 text-[15px] pb-20">{{siteInfo}}</h5>
      </div>
    </div>
  </div>
  <div>
    <EditDialog :title="siteTitle" :description="siteInfo" @close="updapteDialogFlag = false"
        :ispName="selectedISPName" @updated="updatedHandler" />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useAccountStore } from "@/stores"
import { storeToRefs } from "pinia"
import EditDialog from "./UpdateModal.vue"
const accountStore = useAccountStore()
const { user, siteTitle, siteInfo } = storeToRefs(accountStore)

const updapteDialogFlag = ref(false)

accountStore.getMeta();

const updatedHandler = async () => {
  updapteDialogFlag.value = false
  await accountStore.getMeta();
}

</script>