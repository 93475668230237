<template>
    <div class="modal fade" id="authenticationModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered  w-[400px] ">
            <div class="modal-content">
                <Form @submit="onUpdate" :validation-schema="schema" v-slot="{ errors }" class="">

                    <div class="modal-header">
                        <h1 class="modal-title fs-5">Update User Authentication</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body" v-if="props.license">
                        <div class="text-sm  pt-4 space-y-2">
                            <span @click="authType = 'username'" style="cursor: pointer;" class="flex">
                                <div class="w-5 mr-4">
                                    <input class="form-check-input shadow-none" type="checkbox"
                                        :checked="authType === 'username'" />

                                </div>
                                <span class="text-sm">Username:Password</span>
                            </span>
                            <Field name="usernameVal" rules="required" v-slot="{ field }" v-model="username">

                                <div class="relative">
                                    <input v-bind="field" :disabled="authType !== 'username'"
                                        class=" form-control shadow-none rounded-0 text-black" />
                                    <i class="flaticon-refresh absolute right-0  mx-4 top-1/2 -translate-y-1/2 cursor-pointer"
                                        @click="generateRandomCredentials"></i>
                                    <span v-if="authType === 'username'" class="text-danger">{{ errors.usernameVal }}</span>
                                </div>

                            </Field>
                            <span @click="authType = 'ip'" style="cursor: pointer;" class="flex">
                                <div class="w-5 mr-4">
                                    <input class="form-check-input shadow-none" type="checkbox"
                                        :checked="authType === 'ip'" />
                                </div>
                                <span class="text-sm">IP Whitelist</span>
                            </span>
                            <Field name="ipAddressVal" rules="required" v-slot="{ field }" v-model="ips">
                                <input v-bind="field" :disabled="authType !== 'ip'" placeholder="1.1.1.1,2.2.2.2"
                                    class="form-control shadow-none rounded-0 text-black" />
                                <span v-if="authType === 'ip'" class="text-danger">{{ errors.ipAddressVal }}</span>
                            </Field>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        <button type="submit" :disabled="buttonDisableFlag" class="btn btn-primary">Update</button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, watch, defineProps, defineEmits, watchEffect } from 'vue';
import { Form, Field } from 'vee-validate';
import * as Yup from 'yup';

import {useProxyStore} from "@/stores"
const authType = ref('username');
const username = ref('')
const ips = ref('')
const disabledAuthType = ref(null);
const proxyStore = useProxyStore()
const buttonDisableFlag = ref(false)
const props = defineProps({
    license: {
        type: Object, // Assuming user object has a name property
        required: true
    }
});

watchEffect(() => {
    authType.value = props?.license  && props.license.authentication_user !== null && props.license.authentication_user !== "" ? "username" : "ip";
    ips.value = props?.license ? props.license.authorization_ips : "";
    username.value = props?.license ? props.license.authentication_user : "";
})

const schema = Yup.object().shape({
  usernameVal: Yup.string()
    .test('is-valid-username', 'Username:Password format is required', function(value) {
        if(authType.value === 'username')  {
            return /^(\S+):(\S+)$/.test(value)
        } else {
            return true
        }
    })
    .when('authType', {
      is: 'username',
      then: Yup.string().required('Username:Password is required'),
    }),
  ipAddressVal: Yup.string()
    .test('is-valid-ip', 'IP address format is required', function (value) {
        if(authType.value === 'ip'){
        const ips = value?.split(',');
        return ips?.every(ip =>
          /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(ip.trim())
        );
    } else return true
    })
    .when('authType', {
      is: 'ip',
      then: Yup.string().required('IP address is required'),
    }),
});
const onUpdate = ( (values) => {
    const { usernameVal, ipAddressVal } = values
    let auth_user = usernameVal
    let auth_ip = ipAddressVal
    switch (authType.value){
        case "username":
            auth_ip = null
            break
        case "ip":
            auth_user = null
            break
    }
    buttonDisableFlag.value = true
    proxyStore.updateUserAuthentication(props.license.uuid, auth_user, auth_ip).then(res=>{
        buttonDisableFlag.value = false
        emit('close');
    })
});
watch(authType, () => {
    // Update disabled authentication type when switching between radio buttons
    disabledAuthType.value = authType.value === 'username' ? 'ip' : 'username';
});
const emit = defineEmits(['inInput', 'submit'])
function generateRandomCredentials() {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let user_name = '';
    let password = '';
    for (let i = 0; i < 7; i++) {
        user_name += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    for (let i = 0; i < 7; i++) {
        password += Math.floor(Math.random() * 10); // Generates a random digit between 0 and 9
    }
    const newUsername = `u${user_name}:p${password}`
    // alert(newUsername)
    // handleSubmit({ usernameVal: newUsername });
    username.value = newUsername;
}
generateRandomCredentials();
</script>