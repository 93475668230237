<template>
  <MainHeader v-if="user" />
  <MainSidebar v-if="user" />
  <ChatComponent v-if="user?.role === 'user'" />
  <CustomAlert />
  <div class=" d-flex flex-column transition overflow-hidden bg-gray" :class="user ? 'main-content' : ' '">
    <router-view />
    <!-- <MainFooter /> -->
  </div>
  
</template>

<script setup>
import { watchEffect, ref } from "vue";
import stateStore from "./utils/store";
import { useHead } from '@vueuse/head';
import MainHeader from "./components/Layouts/MainHeader.vue";
import MainSidebar from "./components/Layouts/MainSidebar.vue";
// import MainFooter from "./components/Layouts/MainFooter.vue";
import CustomAlert from "./components/CustomAlert.vue";
import ChatComponent from "./components/Customer/ChatComponent.vue"
import { useAccountStore } from "@/stores"
import { storeToRefs } from "pinia"
const stateStoreInstance = stateStore;
const accountStore = useAccountStore()
accountStore.getAccount();
accountStore.getMeta()
const { user, siteTitle, siteInfo } = storeToRefs(accountStore)

const pageTitle = ref('Dynamic Page Title');
const pageDescription = ref('This is a dynamic description for the page.');

// Set meta tags dynamically

watchEffect(() => {
  useHead({
    title: siteTitle.value ? siteTitle.value : 'Proxy',
    meta: [
      { name: 'description', content: siteInfo.value ? siteInfo.value : '' },
      { property: 'og:title', content: siteTitle.value ? siteTitle.value : 'Proxy'},
      { property: 'og:description', content: siteInfo.value ? siteInfo.value : ''},
    ],
  });

})

watchEffect(() => {
  document.body.classList.add("bg-body-secondary");
    if (stateStoreInstance.open) {
      document.body.classList.remove("sidebar-show");
      document.body.classList.add("sidebar-hide");
    } else {
      document.body.classList.remove("sidebar-hide");
      document.body.classList.add("sidebar-show");

    }
});
</script>