<template>
    <div class="modal fade" id="packageUpdateModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered  w-[500px] ">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5">Update Package</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body " v-if="selectedItem">
                    <div class="overflow-hidden">
                        <div class="float-left w-1/2 px-3 py-2 h-20">
                            Package Name:
                            <v-text-field v-model="packageName" variant="outlined" density="compact"
                                rounded="0"></v-text-field>
                        </div>
                        <div class="float-left w-1/2 px-3 py-2 h-20">
                            Price:
                            <v-text-field v-model="price" variant="outlined" density="compact" rounded="0"
                                type="number" style="outline: none;"></v-text-field>
                        </div>
                        <!-- <div class="float-left w-1/2 px-3 py-2 h-20">
                    Time (Date):
                    <v-text-field v-model="timeDate" variant="outlined" density="compact" rounded="0" type="number"></v-text-field>
                </div> -->
                        <div class="w-full flex">
                            <div class="w-1/2">

                                <div class="w-full px-3 py-2 h-20">
                                    Time Unit:
                                    <v-btn-toggle v-model="timeUnit" color="deep-purple-accent-3" group mandatory
                                        rounded="0" style="height: 40px;" class="border w-full">
                                        <v-btn value="DAY" class="w-1/3 bg-white text-black"
                                            style="outline: none; height: 100%;">
                                            <p class="text-xs">Day</p>
                                        </v-btn>
                                        <v-btn value="WEEK" class="w-1/3 bg-white text-black"
                                            style="outline: none; height: 100%;">
                                            <p class="text-xs">Week</p>
                                        </v-btn>
                                        <v-btn value="MONTH" class="w-1/3 bg-white text-black"
                                            style="outline: none; height: 100%;">
                                            <p class="text-xs">Month</p>
                                        </v-btn>
                                    </v-btn-toggle>
                                </div>
                                <div class="w-full px-3 py-2 h-20 ">
                                    Status:
                                    <v-select v-model="status" :items="statusOptions" variant="outlined"
                                        density="compact" rounded="0"></v-select>
                                </div>
                                <div class="w-full px-3 py-2 h-20">
                                    Minimum IP Rotation Time:
                                    <v-text-field v-model="rotationMin" variant="outlined" density="compact" type="number"
                                        rounded="0"></v-text-field>
                                </div>
                                <div class="fw-full px-3 py-2 h-20">
                                    Daily Data Limits (GB):
                                    <v-text-field v-model="dataLimits" variant="outlined" density="compact" rounded="0"
                                        type="number" step="0.1" style="outline: none;"></v-text-field>
                                </div>
                            </div>
                            <div class=" w-1/2 px-3 py-2">
                                ISP Image
                                <v-card rounded="0">
                                    <!-- <v-file-input multiple label="Choose an image" accept="image/*"
                                        @change="onFileChange" ref="fileInput" style="display: none"></v-file-input> -->
                                    <v-img height="200" ropunded="0"
                                        :src="imageUrl ? imageUrl : 'https://webcolours.ca/wp-content/uploads/2020/10/webcolours-unknown.png'"
                                        cover
                                        class="w-full cursor-pointer hover:brightness-75" />
                                </v-card>
                            </div>
                        </div>
                        <div class="float-left w-1/2 px-3 py-2 h-20">
                            Supplier:
                            <v-combobox v-model="supplier" :items="customers" placeholder="Select" rounded="0"
                                variant="outlined" style="outline: none;" density="compact" :hide-no-data="false">
                                <template v-slot:no-data>
                                    <v-list-item density>
                                        <v-list-item-title density="compact" variant="disabled">
                                            <p class="text-sm text-gray-600 text-center">Not found</p>
                                        </v-list-item-title>
                                    </v-list-item>
                                </template>
                            </v-combobox>
                        </div>
                        <div class="float-left w-1/2 px-3 py-2 h-20">
                            Cost price:
                            <v-text-field v-model="costPrice" variant="outlined" density="compact" rounded="0"
                                type="number" style=""></v-text-field>
                        </div>
                        
                        <!-- <div class="float-left w-1/2 px-3 py-2 ">
                    <v-switch density="dense" label="Allow IP Change" color="success" v-model="changeIpFlag"
                    label-placement="left"></v-switch>
                </div>
                <div class="float-left w-1/2 px-3 py-2 ">
                    <v-switch density="dense" label="Allow IP Location" color="success" v-model="changeLocationFlag"
                    label-placement="left"></v-switch>
                </div> -->
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" :disabled="buttonDisableFlag" class="btn btn-primary"
                        @click="handleUpdate">Update</button>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
import { ref, watchEffect, defineProps, defineEmits } from "vue";

import { useAdminPackageStore, useAlertStore } from "@/stores"
import { storeToRefs } from 'pinia';

const adminPackageStore = useAdminPackageStore()
const alertStore = useAlertStore()
const props = defineProps({
    item: {
        type: Object, // Assuming user object has a name property
        required: true
    },
});

const statusOptions = ref([
    'Active',
    'Off',
]);
const buttonDisableFlag = ref(false)

const { customers } = storeToRefs(adminPackageStore)
adminPackageStore.getCustomers()
const selectedItem = ref(null)
const price = ref(null);
const timeDate = ref(1);
const timeUnit = ref(null);
const status = ref(null);
const ipChangeTime = ref(null);
const packageName = ref(null);
const imageUrl = ref(null);
const dataLimits = ref(2);
const rotationMin = ref(5);
const supplier = ref('')
const costPrice = ref(null)

watchEffect(() => {
    if (props.item) {
        selectedItem.value = props.item
        price.value = selectedItem.value.price;
        timeUnit.value = selectedItem.value.time_unit;
        status.value = selectedItem.value.status;
        packageName.value = selectedItem.value.name;
        dataLimits.value = selectedItem.value?.data_limit ? selectedItem.value?.data_limit : 2;
        rotationMin.value = selectedItem.value?.rotation_time ? selectedItem.value?.rotation_time : 5;
        supplier.value = selectedItem.value?.supplier ? selectedItem.value?.supplier : '';
        costPrice.value = selectedItem.value?.costPrice ? selectedItem.value?.costPrice : null;
        imageUrl.value = selectedItem.value.isp_image ?
            `${process.env.VUE_APP_VUE_SOCKET_URL}/${selectedItem.value.isp_image}` :
            'https://webcolours.ca/wp-content/uploads/2020/10/webcolours-unknown.png'
        
    }
}, [props.item])
// const changeIpFlag = ref(selectedItem.value.allow_change_ip==="Yes");
// const changeLocationFlag = ref(selectedItem.value.allow_change_location==="Yes");

const fileInput = ref(null)
const onFileChange = (event) => {
    const file = event.target.files[0];
    imageUrl.value = URL.createObjectURL(file);
};

const openFileInput = () => {
    fileInput.value.$el.querySelector('input').click();
};

const emit = defineEmits(['inInput', 'submit'])
const handleUpdate = () => {
    // Call your function to add the values
    // For example:
    if(supplier.value) {
        if(!costPrice.value) {
            alertStore.error("Set the cost price if this is the supplier's.")
            return;
        } else {
            if(costPrice.value > price.value) {
                alertStore.error("Cost price is bigger than package price")
                return
            }
        }
    }
    const updateRequest = {
        uuid: selectedItem.value.uuid,
        price: price.value,
        timeDate: timeDate.value,
        timeUnit: timeUnit.value,
        status: status.value,
        ipChangeTime: rotationMin.value ? rotationMin.value : 0,
        packageName: packageName.value,
        dataLimits: dataLimits.value,
        rotationMin: rotationMin.value,
        supplier: supplier.value ? supplier.value : '',
        costPrice: costPrice.value ? costPrice.value : 0
        // changeIpFlag: changeIpFlag.value,
        // changeLocationFlag: changeLocationFlag.value,
    }
    let formData = new FormData();
    // Append package data to FormData
    for (const key in updateRequest) {
        formData.append(key, updateRequest[key]);
    }

    // Append image file to FormData

    // const file = fileInput.value.$el.querySelector('input').files[0];
    // if (file) formData.append('file', file);

    buttonDisableFlag.value = true;
    // Send FormData to server
    adminPackageStore.updatePackage(formData).then(() => {
        buttonDisableFlag.value = false;
        emit('updated')
    });
};
watchEffect(() => {
    if (price.value) {
        price.value = Math.max(price.value, 0)
    }
}, [price.value])

</script>