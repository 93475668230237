<template>
  <div class="modal fade" id="couponUpdateModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered  w-[500px] ">
          <div class="modal-content">
              <div class="modal-header">
                  <h1 class="modal-title fs-5">Add New Coupon</h1>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body ">
                  <div >
                      <div class="float-left w-1/2 px-2 py-2 h-20">
                          Coupon Code:
                          <v-text-field v-model="couponCode" variant="outlined" density="compact" :disabled="true" 
                              rounded="0">
                              <template v-slot:append-inner>
                                <v-icon icon="mdi-refresh" class=" cursor-pointer" @click="generateCouponCode(6)"></v-icon>
                              </template>
                          </v-text-field>  
                      </div>
                      <div class="float-left w-1/2 px-2 py-2 h-20">
                          Expire Date:
                          <VueDatePicker v-model="expireDate" format='dd/MM/yyyy' :enable-time-picker="false"></VueDatePicker>
                      </div>
                      <div class="w-full float-left px-2 py-2 h-20 ">
                        Discount Type:
                          <div>
                              <v-btn-toggle v-model="discountType" color="deep-purple-accent-3" group mandatory
                                  rounded="0" style="height: 40px;" class="border w-full">
                                  <v-btn value="percentage" class="w-1/2 bg-white text-black h-full"
                                      style="outline: none; height: 100%;">
                                      <p class="text-xs">Percentage discount</p>
                                  </v-btn>
                                  <v-btn value="flat" class="w-1/2 bg-white text-black h-full"
                                      style="outline: none; height: 100%;">
                                      <p class="text-xs">Flat rate discount</p>
                                  </v-btn>
                              </v-btn-toggle>
                          </div>
                      </div>
                      <div class="w-1/2 float-left px-2 py-2 h-20">
                          Value: 
                          <span v-if="discountType === 'percentage'">(%)</span>
                          <span v-else>($)</span>
                          <v-text-field v-model="discountValue" variant="outlined" density="compact" rounded="0"
                              type="number" style=""></v-text-field>
                      </div>
                      <div class="w-1/2 float-left px-2 py-2 h-20">
                        Coupon Type:
                        <div>
                          <v-btn-toggle v-model="couponType" color="deep-purple-accent-3" group mandatory
                              rounded="0" style="height: 40px;" class="border w-full">
                              <v-btn value="purchase" class="w-1/2 bg-white text-black h-full"
                                  style="outline: none; height: 100%;">
                                  <p class="text-xs">Purchase</p>
                              </v-btn>
                              <v-btn value="deposit" class="w-1/2 bg-white text-black h-full"
                                  style="outline: none; height: 100%;">
                                  <p class="text-xs">Deposits</p>
                              </v-btn>
                          </v-btn-toggle>
                        </div>
                      </div>
                      <div class="w-1/2 float-left px-2 py-2 h-20">
                          User:
                          <v-combobox v-model="selectedUserEmail" :items="customers" placeholder="Select" rounded="0"
                              variant="outlined" style="outline: none;" density="compact" :hide-no-data="false">
                              <template v-slot:no-data>
                                  <v-list-item density>
                                      <v-list-item-title density="compact" variant="disabled">
                                          <p class="text-sm text-gray-600 text-center">Not found</p>
                                      </v-list-item-title>
                                  </v-list-item>
                              </template>
                          </v-combobox>
                      </div>
                      <div v-if="couponType === 'purchase'" class="w-1/2 float-left px-2 py-2 h-20">
                          Package Price: 
                          <v-text-field v-model="price" variant="outlined" density="compact" rounded="0"
                              type="number" style=""></v-text-field>
                      </div>
                  </div>
              </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                  <button type="button" :disabled="buttonDisableFlag" class="btn btn-primary"
                      @click="handleUpdate">Update</button>
              </div>
          </div>
      </div>
  </div>
</template>


<script setup>
import { ref, watchEffect, defineProps, defineEmits } from 'vue';
import { useAdminLicenseStore, useAdminCouponStore } from "@/stores"
import VueDatePicker from '@vuepic/vue-datepicker';
import { storeToRefs } from 'pinia';
import { format } from 'date-fns';

const adminLicenseStore = useAdminLicenseStore();
const adminCouponStore = useAdminCouponStore();

const props = defineProps({
    item: {
        type: Object, // Assuming user object has a name property
        required: true
    },
});

const { customers } = storeToRefs(adminLicenseStore)

adminLicenseStore.getCustomers()

const buttonDisableFlag = ref(false);
const couponCode = ref('');
const expireDate = ref(new Date());
const discountType = ref('percentage');
const discountValue = ref(0);
const couponType = ref('purchase');
const selectedUserEmail = ref('All');
const price = ref(0)
const emit = defineEmits(['inInput', 'submit'])

watchEffect(() => {
    if(props.item){
        const [day, month, year] = props.item.expireDate.split('/');
        couponCode.value = props.item.couponCode;
        expireDate.value = new Date(`${year}-${month}-${day}`);
        discountType.value = props.item.discountType;
        discountValue.value = props.item.discountValue;
        couponType.value = props.item.couponType;
        selectedUserEmail.value = props.item.user;
        price.value = props.item.price;
    }
})

const handleUpdate = () => {
  const packageRequest = {couponCode: couponCode.value, 
                          expireDate: format(expireDate.value, 'dd/MM/yyyy'),
                          discountType: discountType.value,
                          discountValue: discountValue.value,
                          couponType: couponType.value,
                          user: selectedUserEmail.value,
                          price: price.value };

  // const file = fileInput.value.$el.querySelector('input').files[0];
  // formData.append('file', file);

  buttonDisableFlag.value = true;
  // Send FormData to server
  adminCouponStore.updateCoupon(packageRequest).then(() => {
    adminCouponStore.loadCoupons();
    buttonDisableFlag.value = false;
    emit('close');
  });
};
function generateCouponCode(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'; // Allowed characters
    let tempCouponCode = '';
    
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        tempCouponCode += characters[randomIndex];
    }

    couponCode.value = tempCouponCode;
}

</script>
