<template>
  <div class="user-list" :style="`height: ${computedHeight}px;`">
    <div
      v-for="user in (users.filter(u => [u.email, u.name].join(',').toLowerCase().includes(searchname.toLowerCase())))"
      :key="user.id"
      class="flex flex-row h-[55px] items-center justify-between p-2"
      @click="selectUser(user)"
      :class="{ selected: user.user_id === selectedUserId }"
    >
      {{ user.name }} ({{ user.email }}) 
      <span v-if="user.unreadCount" class=" z-10 text-[12px] w-[25px] min-w-[25px] badge rounded-pill flex bg-danger items-center justify-center aspect-square ">{{user.unreadCount}}</span>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits, defineProps, onMounted, onBeforeUnmount, watchEffect } from "vue";

const props = defineProps({
  users: Array,
  searchname: String
});
const emit = defineEmits(['selectUser']);
const selectedUserId = ref(null);
const offsetHeight = 250; // Adjust this value as needed

const computedHeight = ref(window.innerHeight - offsetHeight);

const handleResize = () => {
  computedHeight.value = window.innerHeight - offsetHeight;
};
onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});
const selectUser = (user) => {
  selectedUserId.value = user.user_id;
  emit('selectUser', user);
};
</script>

<style scoped>
.user-list {
  width: 300px;
  border-right: 1px solid #ccc;
  overflow-y: auto;
}

.user-list div {
  cursor: pointer;
  padding: 5px 0;
}

.user-list div:hover {
  background: #b4b4b4;
}

.user-list .selected {
  background: #cfcfcf; /* Or any color you prefer for the selected background */
  color: #fff; /* Optional: Change text color for better contrast */
}
</style>
